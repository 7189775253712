/*
| Developed by Starton
| Filename : common.contract.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/

/*
|--------------------------------------------------------------------------
| Attachment
|--------------------------------------------------------------------------
*/
export type AttachmentBase = {
  name: string;
  size: number;
  extname: string;
  mimeType: string;
  url?: string;
  meta?: {
    dimension?: {
      width: number;
      height: number;
    };
    orientation?: {
      value: number;
      description: string;
    };
  };
};

export type Variant = AttachmentBase & {
  key: string;
  folder: string;
};

export type Attachment = AttachmentBase & {
  originalName: string;
  thumbnail: Variant;
  original: Variant;
};

/*
|--------------------------------------------------------------------------
| Pagination
|--------------------------------------------------------------------------
*/
export type Pagination = {
  total: number;
  perPage: number;
  currentPage: number;
  lastPage: number;
  firstPage: number;
  firstPageUrl: string;
  lastPageUrl: string;
  nextPageUrl: string;
  previousPageUrl: string;
};

export type PaginatedResponse<T> = {
  data: Array<T>;
  meta: Pagination;
};

export interface PaginatedParams {
  page?: number;
  limit?: number;
}

/*
|--------------------------------------------------------------------------
| Error codes
|--------------------------------------------------------------------------
*/
export const ErrorCodes = {
  UNKNOWN: "UNKNOWN",

  // Common
  // ----------------------------------------------------------------------------
  E_INTERNAL_SERVER_ERROR: "E_INTERNAL_SERVER_ERROR",
  E_BAD_REQUEST: "E_BAD_REQUEST",
  E_CONFLICT: "E_CONFLICT",
  E_RATE_LIMIT: "E_RATE_LIMIT",
  E_ENTITY_NOT_FOUND: "E_ENTITY_NOT_FOUND",
  E_VALIDATION: "E_VALIDATION",
  E_INVALID_INPUT: "E_INVALID_INPUT",
  E_UNAUTHORIZED: "E_UNAUTHORIZED",
  E_FORBIDDEN: "E_FORBIDDEN",
  E_INVALID_TOKEN: "E_INVALID_TOKEN",
  E_NOT_OWNER: "E_NOT_OWNER",
  E_INVALID_PASSWORD: "E_INVALID_PASSWORD",

  // User
  // ----------------------------------------------------------------------------
  E_USER_NOT_ALLOWED: "E_USER_NOT_ALLOWED",
  E_EMAIL_UNVERIFIED: "E_EMAIL_UNVERIFIED",
} as const;

export type ApiError = (typeof ErrorCodes)[keyof typeof ErrorCodes];

/*
|--------------------------------------------------------------------------
| String length
|--------------------------------------------------------------------------
*/
/**
 * Configuration options to tweak the database settings.
 */
export const STRING_LENGTH = {
  /**
   * Very short string length (32 characters).
   */
  veryShort: 32,
  /**
   * Short string length (64 characters).
   */
  short: 64,
  /**
   * Medium string length (256 characters).
   */
  medium: 256,
  /**
   * Medium string length (512 characters).
   */
  long: 512,
  /**
   * Long string length (1024 characters).
   */
  veryLong: 1024,
  /**
   * Very long string length (2048 characters).
   */
  veryVeryLong: 2048,
};
