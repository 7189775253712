/*
| Developed by Dirupt
| Filename : endpoints.service.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

/*
|--------------------------------------------------------------------------
| Helper to create an axios instance
|--------------------------------------------------------------------------
*/
export const createAxiosInstance = ({
  baseURL = process.env.NEXT_PUBLIC_API_HOST as string,
  withCredentials = true,
  headers = { "Content-Type": "application/json" },
}: {
  baseURL?: string;
  withCredentials?: boolean;
  headers?: object;
} = {}): AxiosInstance => {
  return axios.create({
    baseURL,
    withCredentials,
    headers,
  });
};

/*
|--------------------------------------------------------------------------
| Service
|--------------------------------------------------------------------------
*/
export class EndpointsService {
  private static axiosInstance: AxiosInstance = createAxiosInstance();

  public static setAxiosInstance(instance: AxiosInstance) {
    this.axiosInstance = instance;
  }

  public static createEndpointHelper<ReturnType>(config: AxiosRequestConfig) {
    const fetcher = async () =>
      this.axiosInstance<ReturnType>(config).then((res) => res.data);
    const uri = axios.getUri(config);
    return { fetcher, uri };
  }
}
