/*
| Developed by Starton
| Filename : AuthDialog.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

"use client";

import React, { useState } from "react";
import { Dialog, Tab, Tabs, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useAuthContext } from "../provider";
import { AuthLoginForm } from "@/modules/auth/screens/AuthLoginForm";

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const StyledDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    width: "100%",
    maxWidth: 500,
  },
}));

const TabPanel = styled(Box)({
  padding: 24,
});

/*
|--------------------------------------------------------------------------
| Types
|--------------------------------------------------------------------------
*/
type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

/*
|--------------------------------------------------------------------------
| Components
|--------------------------------------------------------------------------
*/
const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <TabPanel
      role="tabpanel"
      hidden={value !== index}
      id={`auth-tabpanel-${index}`}
      aria-labelledby={`auth-tab-${index}`}
      {...other}
    >
      {value === index ? children : null}
    </TabPanel>
  );
};

export const AuthDialog = () => {
  const { isAuthDialogOpen, closeAuthDialog } = useAuthContext();
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <StyledDialog
      open={isAuthDialogOpen}
      onClose={closeAuthDialog}
      aria-labelledby="auth-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        aria-label="auth tabs"
        variant="fullWidth"
      >
        <Tab label="Sign In" id="auth-tab-0" aria-controls="auth-tabpanel-0" />
        <Tab label="Sign Up" id="auth-tab-1" aria-controls="auth-tabpanel-1" />
      </Tabs>

      <CustomTabPanel value={activeTab} index={0}>
        <AuthLoginForm />
      </CustomTabPanel>

      <CustomTabPanel value={activeTab} index={1}>
        {/* Sign Up Form Component will go here */}
        Sign Up Form
      </CustomTabPanel>
    </StyledDialog>
  );
};
