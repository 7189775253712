/*
| Developed by Starton
| Filename : useApiError.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import { AxiosError } from "axios";
import React from "react";
import { ErrorCodes } from "@/services/api/common.contract";

/*
|--------------------------------------------------------------------------
| Types
|--------------------------------------------------------------------------
*/
type ErrorCode = (typeof ErrorCodes)[keyof typeof ErrorCodes];

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
const errorMessages: Record<ErrorCode, string> = {
  UNKNOWN: "An unknown error occurred",

  // Common
  // ----------------------------------------------------------------------------
  E_INTERNAL_SERVER_ERROR: "An internal server error occurred",
  E_BAD_REQUEST: "The request is invalid",
  E_CONFLICT: "There is a conflict with the request",
  E_RATE_LIMIT: "Rate limit has been reached",
  E_ENTITY_NOT_FOUND: "The requested entity was not found",
  E_VALIDATION: "There is a validation error",
  E_INVALID_INPUT: "The input is invalid",
  E_UNAUTHORIZED: "You are not authorized to perform this action",
  E_FORBIDDEN: "This action is forbidden",
  E_INVALID_TOKEN: "The token is invalid",
  E_NOT_OWNER: "You are not the owner",
  E_INVALID_PASSWORD: "Incorrect password",

  // User
  // ----------------------------------------------------------------------------
  E_USER_NOT_ALLOWED: "User is not allowed",
  E_EMAIL_UNVERIFIED: "Email has not been verified",
};

/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/
export const useApiError = (): [
  string | null,
  (error: unknown) => void,
  () => null,
] => {
  const [error, setError] = React.useState<string | null>(null);

  const handleError = (error: unknown) => {
    let code: ErrorCode = ErrorCodes.UNKNOWN;
    if (error instanceof AxiosError) {
      const responseData = error.response?.data;
      if (
        responseData &&
        typeof responseData === "object" &&
        "code" in responseData
      ) {
        code = responseData.code;
      }
    }
    setError(errorMessages[code] ?? errorMessages.UNKNOWN);
    return code;
  };

  const resetError = () => {
    setError(null);
    return null;
  };

  return [error, handleError, resetError];
};
