/*
| Developed by Starton
| Filename : AuthProvider.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

"use client";

import React, { createContext, useContext, useState } from "react";
import { AuthDialog } from "@/modules/auth/components";

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
type AuthContextType = {
  isAuthDialogOpen: boolean;
  openAuthDialog: (tab?: "login" | "register") => void;
  closeAuthDialog: () => void;
  activeTab: number;
};

type Props = {
  children: React.ReactNode;
};

/*
|--------------------------------------------------------------------------
| Context
|--------------------------------------------------------------------------
*/
const AuthContext = createContext<AuthContextType | undefined>(undefined);

/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/
export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

/*
|--------------------------------------------------------------------------
| Provider
|--------------------------------------------------------------------------
*/
export const AuthProvider = ({ children }: Props) => {
  const [isAuthDialogOpen, setIsAuthDialogOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  // Methods
  // ---------------------------------------------------------------------------
  const openAuthDialog = (tab?: "login" | "register") => {
    setIsAuthDialogOpen(true);
    if (tab === "register") {
      setActiveTab(1);
    } else {
      setActiveTab(0);
    }
  };

  const closeAuthDialog = () => {
    setIsAuthDialogOpen(false);
    setActiveTab(0);
  };

  // Context
  // ---------------------------------------------------------------------------
  return (
    <AuthContext.Provider
      value={{
        isAuthDialogOpen,
        openAuthDialog,
        closeAuthDialog,
        activeTab,
      }}
    >
      {children}
      <AuthDialog />
    </AuthContext.Provider>
  );
};
